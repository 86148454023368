<script context="module" lang="ts">
    import { getPublicFrontendSDK } from '$lib/api/craft';
    import type { Load } from '@sveltejs/kit';
    import TailwindHelper from '$lib/components/TailwindHelper.svelte';

    export const load: Load = async ({ fetch }) => {
        return {
            stuff: {
                cms: getPublicFrontendSDK(fetch),
            },
        };
    };
</script>

<slot />

{#if import.meta.env.MODE === 'development'}
    <!-- TODO: remove TailwindHelper -->
    <TailwindHelper />
{/if}
